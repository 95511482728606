<template>
  <v-card width="500">
    <v-card-title>{{ customerName }}</v-card-title>
    <v-card-subtitle>{{ $moment(order.created).format('DD/MM/YYYY HH:mm') }} - #{{ order.orderId }}</v-card-subtitle>
    <div v-if="showLineItems === true">
      <template v-for="lineItem in order.lineItems">
        <v-list-item
          :key="'lineItem-' + lineItem.uuid"
          link
          :to="'/sales/orders/' + order.uuid"
        >
          <LineItemListItem :line-item="lineItem" />
        </v-list-item>
      </template>
    </div>
    <v-card-actions>
      <v-btn
        text
        link
        :to="'/sales/orders/' + order.uuid"
      >
        {{ $t('open') }}
      </v-btn>
      <v-btn
        text
        link
        target="_blank"
        :href="'https://pay.salescloud.is?uuid=' + this.order.uuid + '&channel=' + this.order.channel.uuid"
        color="primary"
      >
        {{ $t('pay') }}
      </v-btn>
      <v-btn
        text
        v-if="canViewReceipt"
        link
        target="_blank"
        :href="'https://receipt.salescloud.is?uuid=' + this.order.uuid"
        color="primary"
      >
        {{ $t('receipt') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import LineItemListItem from "./lineItem/LineItemListItem.vue"

  export default {
    name: 'OrderCard',
    props: {
      order: {
        type: Object, 
        default: () => {}
      }
    },
    components: {
      LineItemListItem
    },
    data: () => ({
      showLineItems: true
    }),
    computed: {
      canViewReceipt() {
        return this.order.balance !== null && this.order.balance.amount === 0
      },
      canMakePayment() {
        return this.order.balance !== null && this.order.balance.amount > 0 && this.order.channel !== null
      },
      channelName() {

        if(this.order.channel !== null && this.order.channel.label) {
          return this.order.channel.label
        }

        return 'SalesCloud'
      },
      customerName() {

        if(this.order.customer !== null && this.order.customer.address !== null && this.order.customer.address.name_line !== null) {
          return this.order.customer.address.name_line
        }

        return this.$t('anonymous')
      },
      orderIcon() {

        if(this.order.balance === null || this.order.balance.amount > 0) {
          return 'mdi-close'
        }

        return 'mdi-check'
      },
      orderColor() {

        if(this.order.balance === null || this.order.balance.amount > 0) {
          return 'orange'
        }

        return 'green'
      },
      channelLabel() {

        if(this.order.channel !== null && this.order.channel.label !== null) {
          return this.order.channel.label
        }

        return 'SalesCloud'
      }
    }
  }
</script>
